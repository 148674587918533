<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        a) Calculate the energy (in J) of the photon released when an electron in a hydrogen atom
        relaxes from the
        <stemble-latex :content="initialOrb" />
        orbital to a
        <stemble-latex :content="finalOrb" />
        orbital.
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-8"
        prepend-text="$\text{E:}$"
        append-text="$\text{J}$"
        :disabled="!allowEditing"
      />

      <p>b) Determine the wavelength (in nm) of the photon released.</p>

      <calculation-input
        v-model="inputs.input2"
        class="mb-8"
        prepend-text="$\lambda\text{:}$"
        append-text="$\text{nm}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question207',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    initialState() {
      return this.taskState.getValueBySymbol('initialState').content;
    },
    initialOrb() {
      if (this.initialState.value === 1) {
        return '$3\\text{p}$';
      } else if (this.initialState.value === 2) {
        return '$3\\text{d}$';
      } else if (this.initialState.value === 3) {
        return '$4\\text{p}$';
      } else if (this.initialState.value === 4) {
        return '$4\\text{d}$';
      } else if (this.initialState.value === 5) {
        return '$5\\text{p}$';
      } else if (this.initialState.value === 6) {
        return '$5\\text{d}$';
      } else if (this.initialState.value === 7) {
        return '$5\\text{f}$';
      } else {
        return '';
      }
    },
    nFinal() {
      return this.taskState.getValueBySymbol('nFinal').content;
    },
    finalOrb() {
      if (this.nFinal.value === 1) {
        return '$1\\text{s}$';
      } else if (this.nFinal.value === 2) {
        return '$2\\text{s}$';
      } else {
        return '';
      }
    },
  },
};
</script>
